<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <v-card>
      <v-toolbar dense flat class="mb-4">
        <v-toolbar-title class="font-weight-bold">
          {{
            digital
              ? $t("awards.cards.warehouse.title2")
              : $t("awards.cards.warehouse.title")
          }}
        </v-toolbar-title>
        <v-btn class="ml-auto" icon @click="closeDialog">
          <v-icon>$close</v-icon>
        </v-btn>
      </v-toolbar>

      <div v-if="!digital">
        <v-card-text class="py-0">
          <v-autocomplete
            ref="favouriteWh"
            v-model="warehouse"
            :items="warehouses"
            item-text="address.addressName"
            item-value="address.addressId"
            :label="`${$t('awards.cards.warehouse.title')}`"
            :placeholder="$t('awards.cards.warehouse.title')"
            hide-details
            filled
            dense
            outlined
            flat
          ></v-autocomplete>
        </v-card-text>
      </div>
      <v-card-subtitle>
        <div class="d-flex flex-column mt-3">
          <strong class="default--text mb-3"></strong>
          <p v-html="$t('awards.cards.warehouse.body')"></p>
        </div>
      </v-card-subtitle>

      <v-card-actions class="justify-end">
        <v-btn outlined color="secondary" @click="closeDialog">{{
          $t("awards.cards.warehouse.cancel")
        }}</v-btn>
        <v-btn elevation="0" color="primary" @click="selectWarehouse">{{
          $t("awards.cards.warehouse.select")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss"></style>
<script>
import AddressService from "~/service/addressService";
import { mapGetters } from "vuex";

export default {
  name: "WarehouseSelector",
  props: {
    openDialog: { type: Boolean, default: false },
    digital: { type: Boolean, default: false }
  },
  data() {
    return {
      warehouse: null,
      warehouses: null,
      dialog: false
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    async getWarehouses() {
      let res = await AddressService.findWarehouse();
      if (res && res.warehouses) {
        this.warehouses = res.warehouses;
      }
      if (!this.digital) {
        this.removePdv([41]);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$emit("close-dialog");
    },
    async selectWarehouse() {
      this.dialog = false;
      this.$emit("warehouse-selected", this.warehouse);
      this.$emit("close-dialog");
    },
    removePdv(warehouseClassIds) {
      let filteredWarehouses = this.warehouses.filter(warehouse =>
        warehouse.warehouseClass.some(wClass =>
          warehouseClassIds.includes(wClass.warehouseClassId)
        )
      );
      this.warehouses = filteredWarehouses;
    }
  },
  async mounted() {
    await this.getWarehouses();
    if (this.user?.defaultStoreAddress?.addressId) {
      this.warehouse = this.user.defaultStoreAddress.addressId;
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    openDialog(val, Oldval) {
      this.dialog = val;
    },
    // eslint-disable-next-line no-unused-vars
    dialog(val, Oldval) {
      this.$emit("dialog-status", val);
    }
  }
};
</script>
