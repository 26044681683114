<template>
  <div class="profile-container">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <!-- <Breadcrumb :items="breadCrumbs" /> -->

    <v-container v-if="product">
      <router-link
        class="d-flex align-center justify-start go-back mb-5"
        :to="{
          name: 'Awards'
        }"
      >
        <v-icon color="primary">$chevronLeft</v-icon>
        <span class="font-weight-bold primary--text">{{
          $t("awards.booked.goBack")
        }}</span>
      </router-link>

      <AwardsCardDetail
        v-if="product"
        :product="product"
        :key="product.productId"
        @addedToCart="fetchList"
      />

      <!-- <v-row>
        <v-col cols="12" sm="6"></v-col>
        <v-col cols="12" sm="6">
          <div
            class="w-100 pa-3 my-3 d-flex flex-wrap align-center justify-space-between rounded-sm accent"
          >
            <span class="font-weight-bold">{{
              $t("awards.lists.askAwards", {
                num:
                  listData && listData.cartItems?.length
                    ? listData.cartItems.length
                    : 0
              })
            }}</span>

            <v-btn
              :disabled="!(listData && listData.cartItems.length > 0)"
              class="mt-3 mt-sm-0"
              color="primary"
              depressed
              large
              :to="{
                name: 'Booking',
                params: { listId: listData?.cartInfos?.WISHLIST_ID },
                query: {
                  storeId: 6
                }
              }"
            >
              {{ $t("awards.lists.goToCompletePrenotationBtn") }}
            </v-btn>
          </div>
        </v-col>
      </v-row> -->
      <div class=" pa-4 rounded-md awards-description">
        <ebsn-meta
          :target="product"
          path="product_description.CHARACTERISTICS"
          tag="p"
        />
        <ebsn-meta :target="product" path="product_description.OTHER" tag="p" />
      </div>

      <!--
        <ProductDescription
        v-if="product.productId"
        :product="product"
        :key="'product_description_' + product.productId + '_key_' + key"
        :productMetadata="product.metaData"
      />
      -->
      <!--PAGE DIVIDER-->
      <v-row
        v-if="productList && productList.length > 0"
        class="premi-catalog-slider align-center my-5"
      >
        <v-divider class="primary"></v-divider>
        <div class="d-flex align-center px-2 px-sm-5">
          <span class="premi-catalog-slider-title font-weight-bold mr-3">
            Catalogo premi
          </span>
          <span>
            {{
              `${this.$dayjs().year()} - ${this.$dayjs()
                .add(1, "year")
                .year()}`
            }}
          </span>
        </div>
        <v-divider class="primary flex-sm-grow-5"></v-divider>
      </v-row>
      <!--CATALOG SLIDER-->
      <ProductListSlider
        v-if="productList && productList.length > 0"
        class="w-100"
        :cols="isCordova ? 'auto' : '1.'"
        :sm="isCordova ? 'auto' : 2"
        :shuffle="true"
        :isAwardCard="true"
        :productList="productList"
        :paginationClass="'swiper-pagination-'"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.go-back {
  text-decoration: none;
}
.awards-description {
  background-color: var(--v-white-base);
  border: 1px solid var(--v-grey-lighten1);
}

.product-detail {
  .award-tag {
    z-index: 2;
    padding: 2px;
    top: -10px;
    color: white;
    font-weight: bold;
  }
  &-interaction {
    .actions,
    &--cta {
      flex: 1 1 auto;
    }
    &--points {
      flex: 1 1 60%;
      min-width: fit-content;

      p {
        margin: 0;
      }
      strong {
        margin-left: 5px;
      }
    }
    &--cta {
      .v-btn__content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .premi-catalog-slider {
    &-title {
      font-size: 32px;
    }
    .v-divider:last-of-type {
      flex-grow: 5 !important;
    }
  }
}
</style>
<script>
// import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import AwardsCardDetail from "@/components/awards/AwardsCardDetail.vue";
import productMixin from "~/mixins/product";
import customService from "@/service/customService";
import { mapState, mapGetters } from "vuex";
import ProductService from "~/service/productService";
import get from "lodash/get";

export default {
  name: "AwardsDetail",
  mixins: [productMixin],
  props: {
    showBtn: { type: Boolean, default: true }
  },
  components: {
    AwardsCardDetail,
    // ProductDescription,
    ProductListSlider
  },
  data() {
    return {
      product: {
        productId: null
      },
      productList: null,
      category: null,
      key: 1,
      listData: null,
      imageDetailDialog: false,
      warehousePromoId: null
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    2() {
      return this.listData && this.listData.cartItems.length > 0;
    }
  },
  methods: {
    async fetchAwardsProductList() {
      this.loading = true;
      let res = await ProductService.search({
        store_id: 6,
        parent_product_class_id: 10038,
        page: this.page,
        page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
        sort: this.sortFilter
      });
      if (res && res.products) {
        this.productList = res.products;
      } else {
        this.productList = [];
      }
      this.loading = false;
    },
    async fetchList() {
      let res = await customService.getList(6, false);
      this.listData = res ? res : null;
    },
    updateListData(res) {
      this.listData = res ? res : null;
    },
    async getProduct(query) {
      var data = await ProductService.getProductBySlug(query);
      if (data) {
        this.product = data;
      }
    }
  },
  mounted() {
    this.product = this.$route.params.product;
    this.fetchAwardsProductList();
    if (this.isAuthenticated) {
      this.fetchList();
    }
  },
  watch: {
    "$route.params.slug": function(value) {
      this.query = value;
      this.getProduct(this.query);
    }
  },
  metaInfo() {
    const productTitle = get(this, "product.name") || "Premialí";
    const title = `${productTitle} - Catalogo Premialí`;
    const description = `Scopri e richiedi il tuo premio ${productTitle} dal nostro catalogo Premialí.`;
    return {
      title: title,
      link: [
        {
          rel: "canonical",
          href: `https://${window.location.host}${this.$route.path}`
        }
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
