<template>
  <ProductQty :product="product" :isAward="isAward" @open-dialog="openDialog" />
</template>
<script>
import ProductQty from "@/components/product/ProductQty.vue";
import productMixin from "~/mixins/product";
import awardsMixin from "@/mixins/awards";
import customService from "@/service/customService";

export default {
  name: "ProductQtySecondary",
  components: { ProductQty },
  mixins: [productMixin, awardsMixin],
  data() {
    return {
      // openDialog: false
    };
  },
  props: {
    product: { type: Object, required: true },
    isAward: { type: Boolean, default: true }
  },
  methods: {
    // async fetchList() {
    //   let res = await customService.getList(6, false);
    //   this.$emit("updatedListData", res);
    // },

    async addToCart() {
      let productData = {
        product: this.product,
        quantity: this.quantity
      };
      let res = await customService.addProductsToList([productData]);
      if (res) {
        this.$emit("addedToCart");
      }
      // console.log(res);
      // if (res) {
      //   this.fetchList();
      // }
    },
    openDialog(val) {
      this.$emit("open-dialog", val);
    }
  }
};
</script>
