<template>
  <!--PRODUCT DETAIL-->
  <v-row v-if="product" class="product-detail">
    <v-col cols="12" md="6">
      <v-card flat v-if="product.media">
        <ProductImages
          :images="product.media"
          :name="product.name"
          :key="product.productId"
        />
        <div
          class="limited-availability"
          v-if="isAuthenticated && product.limitedAvailability"
        >
          {{ product.limitedAvailability }}
          <span class="small-text">pezzi</span>
        </div>
      </v-card>
    </v-col>
    <v-col class="d-flex flex-column" cols="12" md="6">
      <span
        class="award-tag rounded-sm primary"
        v-if="
          product?.productClasses?.some(
            classItem => classItem.productClassId === 10039
          )
        "
      >
        Premio in quantità limitata
      </span>
      <h2>{{ product.name }}</h2>
      <h3 v-if="product.shortDescr">{{ product.shortDescr }}</h3>
      <p v-if="product.description">{{ product.description }}</p>
      <div v-if="product?.warehousePromo?.view.bubble">
        <span
          class="product-detail-interaction--points d-flex align-center primary--text text-uppercase font-weight-bold col-sm-6"
          v-html="product.warehousePromo?.view.bubble"
        ></span>
        <template v-if="product.available > 0">
          <div class="actions my-3" v-if="showBtn && !isPdvOnly">
            <ProductQty
              @open-dialog="doOpenDialog"
              :product="product"
              :isAward="true"
              :digital="isDigital || isVirtual"
            />
          </div>
          <div v-else-if="isPdvOnly" class="text-uppercase font-weight-bold ">
            Premio prenotabile solo in Punto Vendita
          </div>
        </template>
        <div
          v-else-if="product.available == 0"
          class="actions justify-center text-center text-caption error--text font-weight-bold"
        >
          {{ $t("awards.notAvailable") }}
        </div>
      </div>

      <div v-if="product?.variations && product?.variations[0]?.warehousePromo">
        <span
          class="product-detail-interaction--points d-flex align-center text-uppercase font-weight-bold col-sm-6"
          v-html="product?.variations[0]?.warehousePromo.view.bubble"
        >
        </span>
        <template v-if="product.available > 0">
          <div class="actions my-3" v-if="showBtn && !isPdvOnly">
            <ProductQtySecondary
              @open-dialog="doOpenDialog"
              :product="product.variations[0]"
              :isAward="true"
            />
          </div>
        </template>
        <div
          v-else-if="product.available == 0"
          class="actions justify-center text-center text-caption error--text font-weight-bold"
        >
          {{ $t("awards.notAvailable") }}
        </div>
      </div>
      <div>
        <v-btn
          outlined
          color="primary"
          class="default--text"
          v-on:click="goBack"
          >{{ $t("awards.booking.cta.goBack") }}</v-btn
        >
      </div>
      <WarehouseSelector
        :digital="isDigital || isVirtual"
        :openDialog="openDialog"
        @warehouse-selected="bookPremi"
        @close-dialog="cancelBooking"
        @dialog-status="setDialogStatus"
      />
    </v-col>
  </v-row>
  <!--PRODUCT DESCRIPTION-->
</template>
<style lang="scss">
.limited-availability {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--v-primary-base);
  color: var(--v-primary-on-base);
  padding: 0.5rem;
  border-radius: 0 0 0 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
  z-index: 1;
}
</style>
<script>
import ProductImages from "@/components/product/ProductImages.vue";
import ProductQty from "@/components/product/ProductQty.vue";
import ProductQtySecondary from "@/components/product/ProductQtySecondary.vue";
import productMixin from "~/mixins/product";
import awardsMixin from "@/mixins/awards";
import { mapActions, mapGetters } from "vuex";
import customService from "@/service/customService";
import WarehouseSelector from "@/components/profile/awards/WarehouseSelector.vue";
import ListService from "~/service/listService";

export default {
  name: "AwardsCardDetail",
  mixins: [productMixin, awardsMixin],
  props: {
    product: { type: Object, default: null },
    showBtn: { type: Boolean, default: true },
    storeId: { type: Number, default: 6 }
  },
  data() {
    return {
      key: 1,
      openDialog: false,
      warehouse: null,
      list: null
    };
  },
  mounted() {
    this.cancelBooking();
  },
  components: {
    ProductImages,
    ProductQty,
    ProductQtySecondary,
    WarehouseSelector
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    })
  },
  methods: {
    ...mapActions({ getCart: "cart/loadCart" }),
    // eslint-disable-next-line no-unused-vars
    doOpenDialog(od, prod) {
      if (this.isAuthenticated) {
        this.openDialog = od;
      }
    },

    async bookPremi(wh) {
      if (this.isDigital || this.isVirtual) {
        wh = this.user?.defaultStoreAddress?.addressId || -1;
      }
      // const resAdd = await this.addToCart(this.quantity, {
      //   infos: {}
      // });
      // if (resAdd) {
      //   this.list = {
      //     listId: resAdd.cartInfos.WISHLIST_ID,
      //     wishlistItems: resAdd.cartItems
      //   };
      // }
      await this.bookAward(wh);

      await this.getCart();
    },

    async cancelBooking() {
      if (this.isAuthenticated) {
        const wl = await customService.getList(this.storeId);
        this.list = {
          listId: wl.cartInfos?.WISHLIST_ID,
          wishlistItems: wl.cartItems
        };
        await ListService.emptyList(this.list);
        this.bookInProgress = false;
      }
    },

    async bookAward(wh) {
      // console.log("gggg - booking awards");
      this.warehouse = wh;
      let res = await customService.confirm(this.storeId, wh);
      if (res.response.status === 0) {
        this.bookInProgress = false;
        this.$router.push({
          name: "BookingCompleted",
          params: { store: null }
        });
      } else {
        await ListService.emptyList(this.list);
        this.bookInProgress = false;
        this.response = res.response;
      }
    },
    goBack() {
      this.$router.push("/premiali");
    },
    setDialogStatus(dialogStatus) {
      this.openDialog = dialogStatus;
    }
  }
};
</script>
